import React from "react"
import { graphql } from "gatsby"

import { MentorDetails as Template } from "../components/Mentor/MentorDetails"
import { withAuthentication } from "../hoc/withAuthentication"
import { withMember } from "../hoc/withMember"
import { useApp } from "../hooks/useApp"
import { useSanityMentor } from "../hooks/useSanity"

export const query = graphql`
  query($id: String!) {
    mentor: sanityMentor(_id: { eq: $id }) {
      ...GatsbyMentorFragment
    }
    # template: sanityTemplateMentor {
    #   additionalRelatedText
    #   additionalShareText
    #   settingShowRelated
    #   settingShowSocialShare
    # }
  }
`

export default withAuthentication(withMember(({ data, ...props }): JSX.Element => {
  const { ready } = useApp()
  const { selectedMember, getSelectedMember } = useSanityMentor()

  let mentor = selectedMember ? selectedMember : data?.mentor
  if (ready && !selectedMember) {
    const email = data?.mentor?.contact?.email
    getSelectedMember({email: email})
  }
  return<Template {...props} mentor={mentor} />
}))
